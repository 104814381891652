import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from "./header";
import axios from "axios";
import { NavLink } from 'react-router-dom';
import Pagination from "../common/pagination";
// import QRCode from "react-qr-code";
import bwipjs from 'bwip-js';

class ProductTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storelist: [],
            page: 1,
            setPage: "",
            setStores: "",
            store_name: "",
            store_code: "",
            isModalOpen: false,
            newDoc: "",
            newExt: "",
            newDate2: "",
            search: "",
            show_archived: false,
            loader: true,
            sort: "DESC",
            sort_by: "",
            isPreparationModel: false,
            preparationNum: 0,
            preparationData: []
        }
        console.log(props.params);

        // console.log(props);
    }
    pageChange = (e) => {
        let p = e.target.attributes.getNamedItem("data-page").value;
        this.setState({
            page: Number(p),
            loader: true,
            storelist: []
        }, () => {
            this.getStores();
        });

    };
    // New method to open the modal
    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    // New method to close the modal
    closeModal = () => {
        this.setState({ isModalOpen: false, newDoc: "", newExt: "", newDate2: "" });
    };

    newSavCreate = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/store/create`,
                {
                    "ac_raison_sociale": this.state.store_name,
                    "cl_code": this.state.store_code,
                    "en_num_doc": this.state.newDoc,
                    "en_num_ext": this.state.newExt,
                    "en_date2": this.state.newDate2,
                    "etat_lib": 'sav'
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            if (response.data.status === "Success") {
                this.getStores();
                this.closeModal();
            } else {
                alert("Failed to create. please try again");
            }
        } catch (err) {
            console.log(err);
        }
    }

    getStores = async (search = false) => {
        try {
            let page = this.state.page;
            const currentPath = window.location.hash;
            const productId = currentPath.split('/').pop(); // Get the last part of the path
            console.log(productId);
            let query_string = '?search=';
            if (this.state.search && this.state.search !== "") {
                query_string += this.state.search
            }
            if (this.state.show_archived) { query_string += '&archive=true' };
            if (this.state.sort_by && this.state.sort_by !== "") { query_string += `&sort_by=${this.state.sort_by}&sort=${this.state.sort}`; }

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/store/get-data-code/${productId}/${page}${query_string}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            console.log(response.data);
            if (response.data.status === "Success" && response.data.data.length > 0) {
                this.setState({
                    storelist: response.data.data,
                    totalPage: response.data.totalPage,
                    store_code: response.data.data[0].cl_code,
                    store_name: response.data.data[0].ac_raison_sociale,
                    loader: false,
                })
            } else {
                this.setState({
                    storelist: response.data.data,
                    totalPage: 1,
                    // store_code: response.data.data[0].cl_code,
                    // store_name: response.data.data[0].ac_raison_sociale,
                    loader: false,
                })
            }

            //   setCompanies({
            //     companies: data.data,
            //     totalPage: data.totalPage,
            //   });
        } catch (err) {
            console.log(err);
        }
    };

    getPreparationData = async (e) => {
        try {
            let page = 1;
            let preparationNum = e.target.dataset.num;
            console.log(preparationNum);
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/preparation/get-data/${preparationNum}/${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            console.log(response.data);
            if (response.data.status === "Success" && response.data.data.length > 0) {
                this.setState({
                    isPreparationModel: true,
                    preparationNum: preparationNum,
                    preparationData: response.data.data
                })
            } else {
                this.setState({
                    isPreparationModel: true,
                    preparationNum: preparationNum,
                    preparationData: []
                })
            }
        } catch (err) {
            console.log(err);
        }
    };

    getSVG = (value) => {
        let canvas = document.createElement("canvas");
        bwipjs.toCanvas(canvas, {
            bcid: "datamatrix", // Barcode type
            text: value, // Text to encode
            scale: 2, // 3x scaling factor
            height: 8, // Bar height, in millimeters
            includetext: true, // Show human-readable text
            textxalign: "center" // Always good to set this
        });

        return <div> <img src={canvas.toDataURL("image/png")} alt={value}></img> </div>
    }

    componentDidMount() {
        this.getStores();
    }
    render() {
        return (
            <div>
                <Header />
                <div className="mx-5">
                    <div className="my-2 d-flex justify-content-between">
                        <div>
                            <h6 className="mt-3 ms-2">Code client : {this.state.store_code}</h6>
                            <h6 className="mt-3 ms-2">Client :  {this.state.store_name}</h6>
                        </div>
                        <div>
                            <NavLink className="navlink" to={"/scan/" + this.state.store_code}>
                                <button type="button" className="btn bg-secondary me-2 border rounded text-white px-5 py-auto">Numérisation</button>
                            </NavLink>
                            <button type="button" className="btn bg-secondary me-2 border rounded text-white px-5 py-auto" onClick={this.openModal}>S.A.V</button>
                            {/* <button type="button" className="btn me-2 border rounded-pill py-2 px-5 text-black">Trouver</button> */}
                            <input type="search" className="search-input btn me-2 border rounded-pill py-2  text-black " onChange={(e) => { this.setState({ search: e.target.value, page: 1 }, () => { this.getStores(this.state.search) }) }} value={this.state.search} placeholder="Trouver..."></input>
                            <div className="p-1 d-flex justify-content-between">
                                <label className="d-flex gap-2 pt-1"><input type="checkbox" checked={this.state.show_archived} onChange={(e) => {
                                    this.setState({ show_archived: !this.state.show_archived, page: 1 }, () => { this.getStores() })
                                }} />Afficher les archives</label>

                                <div className="clear-filter">
                                    {(this.state.search !== "" || this.state.show_archived || this.state.sort_by !== "") ?
                                        <span onClick={() => { this.setState({ search: "", show_archived: false, sort: "DESC", sort_by: "" }, () => { this.getStores() }) }}>Effacer le filtre</span>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <div > */}
                    <table className="table">
                        <thead className="thead-dark mx-5">
                            <tr>
                                <th className="text-center">Dashboard</th>
                                <th className="text-center">
                                    <div className="d-flex gap-2 align-items-center">
                                        Date commande
                                        <div class="date-sort-arrows" key="sort-container">
                                            <span data-value="ASC" key="sort-container-span">
                                                <i class="fa  fa-sort-up" key="sort-container-i" onClick={(e) => { this.setState({ sort_by: 'en_date_doc', sort: 'ASC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                            <span data-value="DESC" key="sort-container-span-2">
                                                <i class="fa  fa-sort-down" key="sort-container-i2" onClick={(e) => { this.setState({ sort_by: 'en_date_doc', sort: 'DESC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">Num cmd Danielli</th>
                                <th className="text-center">Stock(%)</th>
                                <th className="text-center">Datamatrix</th>
                                <th className="text-center">Num cmd Client</th>
                                <th className="text-center">
                                    <div className="d-flex gap-2 align-items-center justify-content-center">
                                        Date livraison souhaitée
                                        <div class="date-sort-arrows" key="sort-container">
                                            <span data-value="ASC" key="sort-container-span">
                                                <i class="fa  fa-sort-up" key="sort-container-i" onClick={(e) => { this.setState({ sort_by: 'en_date2', sort: 'ASC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                            <span data-value="DESC" key="sort-container-span-2">
                                                <i class="fa  fa-sort-down" key="sort-container-i2" onClick={(e) => { this.setState({ sort_by: 'en_date2', sort: 'DESC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">Etat</th>
                                <th className="text-center">
                                    <div className="d-flex gap-2 align-items-center justify-content-center">
                                        Opération
                                        <div class="date-sort-arrows" key="sort-container">
                                            <span data-value="ASC" key="sort-container-span">
                                                <i class="fa  fa-sort-up" key="sort-container-i" onClick={(e) => { this.setState({ sort_by: 'op_libel', sort: 'ASC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                            <span data-value="DESC" key="sort-container-span-2">
                                                <i class="fa  fa-sort-down" key="sort-container-i2" onClick={(e) => { this.setState({ sort_by: 'op_libel', sort: 'DESC' }, () => { this.getStores() }) }}></i>
                                            </span>
                                        </div>
                                    </div>

                                </th>
                                <th className="text-center">Reservation</th>
                                <th className="text-center">Marque</th>
                                <th className="text-center">Tracking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.storelist?.length === 0 ? ((this.state.loader) ?
                                    <tr><td colSpan={11}>
                                        <div className='text-center p-2'>
                                            <div class="loader1"><span></span><span></span><span></span><span></span><span></span></div>
                                        </div></td></tr> : <tr><td colSpan={11}>
                                            <div className="d-flex">
                                                <p className="mx-auto">Aucun Enregistrement Trouvé.</p></div></td></tr>
                                ) :
                                    this.state.storelist.map((product, index) => (
                                        <tr className={`${(product.archive) ? 'bg' : ''}`} title={(product.archive) ? 'archive' : ""} key={index}>
                                            <td className="text-center">
                                                {new Date(product.createdAt).toLocaleDateString() + ' '}
                                                {new Date(product.createdAt).toLocaleTimeString()}
                                            </td>
                                            <td className="text-center">
                                                {/* {product.en_date_doc} */}
                                                {new Date(product.en_date_doc).toLocaleDateString() + ' '}
                                                {new Date(product.en_date_doc).toLocaleTimeString()}
                                            </td>
                                            <td className="text-center"><div key={index + "en_num"} style={{color:"blue", cursor:"pointer"}} onClick={this.getPreparationData} data-num={product.en_num_doc}>
                                                {product.en_num_doc}</div>
                                            </td>
                                            <td className="text-center">
                                                {(product.avail_stock>0 && product.avail_stock)? product.avail_stock.toFixed(2)+"%": (product.avail_stock!=null && product.avail_stock>-1)? product.avail_stock: 0 }
                                            </td>
                                            <td className="text-center">
                                                {this.getSVG(product.en_num_doc)}
                                            </td>
                                            <td className="text-center">{product.en_num_ext}</td>
                                            <td className="text-center">
                                                {/* {product.en_date2} */}
                                                {new Date(product.en_date2).toLocaleDateString() + ' '}
                                                {new Date(product.en_date2).toLocaleTimeString()}
                                            </td>
                                            <td className="text-center">
                                                {(product.etat_lib !== 'sav') ? product.etat_lib : ""}
                                            </td>
                                            <td className="text-center">{product.op_libel}</td>
                                            <td className="text-center">{product.res}</td>
                                            <td className="text-center">{product.marque_logotee}</td>
                                            <td className="text-center">{product.awb}</td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                    {/* </div> */}
                </div>

                <div className='mt-2 pagination justify-content-center'>
                    {/* <div className='page-item'> */}
                    <Pagination
                        page={this.state.page}
                        pageChange={this.pageChange}
                        totalPage={this.state.totalPage}
                    />
                    {/* </div> */}
                </div>

                {/* The Modal */}
                <div className={`tsw modal ${this.state.isModalOpen ? "d-block" : ""}`} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Ajouter un nouveau S.A.V</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={(e) => { e.preventDefault(); this.newSavCreate() }}>
                                <div className="modal-body">
                                    {/* Add content for the modal here */}
                                    <p><b>Code client: </b> {this.state.store_code}</p>
                                    <p><b>Client: </b> {this.state.store_name}</p>
                                    <div className="form-data text-center pt-2 pb-2">
                                        <input type="text" placeholder="en_num_doc" required className="mb-2" onChange={(e) => this.setState({ newDoc: e.target.value })}></input>
                                        <input type="text" placeholder="en_num_ext" required className="mb-2" onChange={(e) => this.setState({ newExt: e.target.value })}></input>
                                        <input type="datetime-local" placeholder="en_date2" required className="mb-2 en-date" onChange={(e) => this.setState({ newDate2: e.target.value })}></input>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-secondary">Save</button>
                                    {/* <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Close</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {this.state.isModalOpen && <div className="modal-backdrop show" style={{ zIndex: "1050", backgroundColor: "rgba(0, 0, 0, 0.5)" }}></div>}

                {/* The Preparation Modal */}
                <div className={`tsw preparation modal ${this.state.isPreparationModel ? "d-block" : ""}`} tabIndex="-2" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h5 className="modal-title">Ajouter un nouveau S.A.V</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div className="modal-body p-0">
                                {/* Add content for the modal here */}
                                <p className="pb-4"><b>Num cmd Danielli: </b> {this.state.preparationNum}</p>
                                <div className="preparation-table">
                                    <table className="table">
                                        <thead className="thead-dark mx-5">
                                            <tr>
                                                <th className="text-left">li_designation</th>
                                                <th className="text-center">li_qte_reste</th>
                                                <th className="text-center">dp_stock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.preparationData?.length === 0 ? ((this.state.loader) ?
                                                <tr><td colSpan={3}>
                                                    <div className='text-center p-2'>
                                                        <div class="loader1"><span></span><span></span><span></span><span></span><span></span></div>
                                                    </div></td></tr> : <tr><td colSpan={3}>
                                                        <div className="d-flex">
                                                            <p className="mx-auto">Aucun Enregistrement Trouvé.</p></div></td></tr>
                                            ) : 
                                            this.state.preparationData.map((product, index) => (
                                                <tr className={`${(product.archive) ? 'bg' : ''}`} title={(product.archive) ? 'archive' : ""} key={index}>
                                                    <td className="text-left">{product.li_designation}</td>
                                                    <td className="text-center">{product.li_qte_reste}</td>
                                                    <td className="text-center">{product.dp_stock}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center p-0 border-0">
                                {/* <button type="submit" className="btn btn-secondary">Save</button> */}
                                <button type="button" className="btn btn-secondary" onClick={() => { this.setState({ isPreparationModel: false, preparationData: [], preparationNum: 0 }) }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isPreparationModel && <div className="modal-backdrop show" style={{ zIndex: "1050", backgroundColor: "rgba(0, 0, 0, 0.5)" }}></div>}
            </div>
        );
    }
}

export default ProductTable;
