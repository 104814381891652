import React, { Component } from 'react';

class UploadPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isOpen: false,
    };
  }

  handleFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  openPopup = () => {
      this.setState({ isOpen: true });
        console.log("It's Works");
  };

  closePopup = () => {
    this.setState({ isOpen: false, file: null });
  };

  handleUpload = () => {
    // Perform upload logic here
    // You can access the selected file using the 'file' state variable
    console.log('Uploading file:', this.state.file);
    this.closePopup();
  };

  render() {
    const { file, isOpen } = this.state;

    return (
      <div>
        {/* <button onClick={this.openPopup}>Open Upload Popup</button> */}
        {isOpen ? (
          <div className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Modal title</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closePopup}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Modal body text goes here.</p>
                  <input type="file" onChange={this.handleFileChange} />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={this.handleUpload}>
                    Save changes
                  </button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closePopup}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ):""}
      </div>
    );
  }
}

export default UploadPopup;
