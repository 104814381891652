import React, { Component } from 'react';
import Header from './header';
import axios from 'axios';
import Pagination from "../common/pagination";


class Scan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storelist: [],
            PickedOrder: [],
            page: 1,
            setPage: "",
            setStores: "",
            store_name: "",
            store_code: "",
            scan_text: "Lancer la numérisation",
            scan_start: false,
            scannedData: [],
            scannedSuccessID: [],
            awb: "",
            orderStatus: "Dispatched"
        }
        console.log(props.params);
    }

    handleScanUpdate = async (e) => {
        try {
            if (this.state.scannedSuccessID.length > 0) {
                let bodyData = {
                    ids: this.state.scannedSuccessID,
                    etat_lib: this.state.orderStatus,
                    awb: this.state.awb
                }
                console.log(bodyData);

                const response = await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/store/update-scanned`,
                    bodyData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                        },
                    }
                );
                if (response.data.status == "Success") {
                    alert("Success");
                    this.setState({
                        scannedSuccessID: [],
                        awb: ''
                    })
                } else {
                    alert("Failed please try again later");
                    console.log(response.data);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleScanStartStop = async (e) => {
        let status = this.state.scan_start;
        let scanIDs = this.state.scannedSuccessID;
        if (status) {
            if (scanIDs.length > 0) {

            }
            this.setState({
                scan_start: !status,
                scan_text: "Lancer la numérisation"
            }, () => {
                this.inputEl.blur();
            })
        } else {
            this.setState({
                scan_start: !status,
                scan_text: "Arrêter la numérisation"
            }, () => {
                this.inputEl.focus();
            })
        }

    }

    timeoutReference = null;
    timeout = 1000; // 1 second default timeout

    handleDoneTyping = async (el) => {
        // Custom logic to be executed when typing is done
        console.log('Typing done:', el.value);
        let Data = {
            code: this.state.store_code,
            scannedValue: el.value
        }
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/store/get-scanned`,
            Data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                },
            }
        );
        console.log(response);
        let obj = this.state.scannedData;
        let scannedSuccessID = this.state.scannedSuccessID;
        if (response.data.status == "Failure") {
            console.log("aaa");
            obj.push({ en_num_doc: el.value, en_num_ext: el.value, status: 0 })
        } else {
            let result = response.data.data;
            obj.push({ en_num_doc: result.en_num_doc, en_num_ext: result.en_num_ext, status: 1 });
            scannedSuccessID.push(result._id);
        }
        this.setState({
            scannedData: obj,
            scannedSuccessID: scannedSuccessID
        }, () => {
            console.log(this.state.scannedData);
            el.value = '';
        })
    };

    handleKeyUp = (e) => {
        if (e.type === 'keyup' && e.keyCode !== 8) return;

        if (this.timeoutReference) clearTimeout(this.timeoutReference);
        this.timeoutReference = setTimeout(() => {
            this.handleDoneTyping(this.inputEl);
        }, this.timeout);
    };

    handleBlur = () => {
        this.handleDoneTyping(this.inputEl);
    };

    getStores = async (search = false) => {
        try {
            let page = this.state.page;
            const currentPath = window.location.hash;
            console.log(currentPath);
            let query_string = '?search=';
            if (search) {
                query_string += search
            }
            const productId = currentPath.split('/').pop(); // Get the last part of the path
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/store/get-data-code/${productId}/${page}/${query_string}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            console.log(response.data.data[0].cl_code);
            this.setState({
                storelist: response.data.data,
                // totalPage: response.data.totalPage,
                store_code: response.data.data[0].cl_code,
                store_name: response.data.data[0].ac_raison_sociale
            })

            //   setCompanies({
            //     companies: data.data,
            //     totalPage: data.totalPage,
            //     loader: false,
            //   });
        } catch (err) {
            console.log(err);
        }
    };

    getPickedStores = async (search = "picked") => {
        try {
            let page = this.state.page;
            const currentPath = window.location.hash;
            console.log(currentPath);
            let query_string = '?search=';
            if (search) {
                query_string += search
            }
            const productId = currentPath.split('/').pop(); // Get the last part of the path
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/store/get-data-code/${productId}/${page}/${query_string}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("amira-token")}`,
                    },
                }
            );

            console.log(response.data.data[0].cl_code);
            this.setState({
                PickedOrder: response.data.data,
                totalPage: response.data.totalPage,
            })
        } catch (err) {
            console.log(err);
        }
    };
    pageChange = (e) => {
        let p = e.target.attributes.getNamedItem("data-page").value;
        this.setState({
            page: Number(p)
        }, () => {
            this.getPickedStores();
        });

    };

    componentDidMount() {
        this.getStores();
        this.getPickedStores();
        this.inputEl.addEventListener('keyup', this.handleKeyUp);
        // this.inputEl.addEventListener('blur', this.handleBlur);
    }
    componentWillUnmount() {
        this.inputEl.removeEventListener('keyup', this.handleKeyUp);
        // this.inputEl.removeEventListener('blur', this.handleBlur);
    }

    render() {

        return (
            <div>
                <Header />
                <div className='mx-5'>
                    <div className="app-title">
                        <div>
                            <div>
                                <h6 className="mt-3 ms-2">Code client : {this.state.store_code}</h6>
                                <h6 className="mt-3 ms-2">Client : {this.state.store_name}</h6>
                            </div>
                            {/* <h1>
                                <i className="app-menu__icon fa fa-qrcode"></i>Scan
                            </h1> */}
                        </div>
                        <ul className="app-breadcrumb breadcrumb side">
                            {/* Use appropriate React syntax to render the shortcode */}
                            {/* {do_shortcode('[tsw-panel-change]')} */}
                        </ul>
                    </div>
                    {/* scan report */}
                    <div className="tile mb-4 p-2">
                        <div className="scan_report_generate_container">
                            <div className="invalid report_err"></div>
                        </div>
                    </div>

                    {/* scan area */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tile">
                                <div className="tile-body">
                                    <div className="scanner-area">
                                        <div className='amira-scanner-block'>
                                            <div className="scan-input-action">
                                                <div className="error" style={{ color: 'red' }}></div>
                                                <div className="success" style={{ color: 'green' }}></div>
                                                <input type="text" name="awb" id="str_text" ref={(el) => (this.inputEl = el)} onChange={this.handleKeyUp} disabled={this.state.scan_start ? false : true} />
                                            </div>
                                            <button className="js-add-post btn bg-secondary me-2 border rounded text-white px-5 py-auto" id="start-scan" onClick={this.handleScanStartStop}>
                                                {(!this.state.scan_start) ? this.state.scan_text : "Arrêter la numérisation"}
                                            </button>
                                        </div>
                                        {(this.state.scan_start == false && this.state.scannedSuccessID.length > 0) ?
                                            <div className={`amira-scan-update-block1 d-flex gap-2`} >
                                                <input type="text" placeholder='AWB number....' name="awb" id="awb" onChange={(e) => { this.setState({ awb: e.target.value }) }} value={this.state.awb} />
                                                <button className="js-add-post btn bg-secondary me-2 border rounded text-white px-5 py-auto" id="scanUpdates" onClick={this.handleScanUpdate}>
                                                    Submit
                                                </button>
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tile">
                                <div className="tile-body">
                                    <div className="text-uploded d-flex justify-content-between">
                                        <h1> Commandes scannées</h1>
                                        <div id="uploded_pack" className="scanned_summery">
                                            <div>Nombre total de colis scannes :</div>
                                            <div className="count">{this.state.scannedData.length}</div>
                                        </div>
                                    </div>

                                    <table className="table table-hover table-bordered" id="sampleTable">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>Num cmd Danielli</th>
                                                <th>Num cmd Client</th>
                                                <th>Num cmd Client</th>
                                                {/* <th>DATE D'ENVOI</th> */}
                                                {/* <th>
                                                    STATUT
                                                    <a className="bulk_status_update">
                                                        <i className="fa fa-refresh" aria-hidden="true"></i>
                                                    </a>
                                                </th>
                                                <th>Transporteur</th> */}
                                            </tr>
                                        </thead>
                                        <tbody id="package_detail">
                                            {this.state.scannedData.length < 1 ?
                                                <tr>
                                                    <td className='text-center' colSpan={3}>Aucun enregistrement disponible</td>
                                                </tr>
                                                :
                                                this.state.scannedData.map((item, index) => (
                                                    <tr className='text-center' key={"aa" + index}>
                                                        <td> <span className={(item.status == 1) ? 'picked-bg' : 'new-bg'}>{item.en_num_doc}</span></td>
                                                        <td> <span className={(item.status == 1) ? 'picked-bg' : 'new-bg'}>{item.en_num_ext}</span></td>
                                                        <td> <span className={(item.status == 1) ? 'picked-bg' : 'new-bg'}>{item.en_num_ext}</span></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>{/* Pagination elements */}</tfoot>
                                    </table>
                                </div>
                                <div className="text-uploded d-flex justify-content-between pt-4">
                                    <h2>Commandes pretes a etre expédiés</h2>
                                    {/* <div id="uploded_pack" className="scanned_summery"> */}
                                    {/* <div>Nombre total de colis scannes :</div> */}
                                    {/* <div className="count">{this.state.scannedData.length}</div> */}
                                    {/* </div> */}
                                </div>
                                <table className="table table-hover table-bordered">
                                    <thead className="thead-dark mx-5">
                                        <tr>
                                            <th className="text-center">Dashboard</th>
                                            <th className="text-center">Date commande</th>
                                            <th className="text-center">Num cmd Danielli</th>
                                            {/* <th className="text-center">Datamatrix</th> */}
                                            <th className="text-center">Num cmd Client</th>
                                            <th className="text-center">Date livraison souhaitée</th>
                                            <th className="text-center">Etat</th>
                                            <th className="text-center">Opération</th>
                                            <th className="text-center">Reservation</th>
                                            <th className="text-center">Marque</th>
                                            {/* <th className="text-center">Tracking</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.PickedOrder.length < 1 ?
                                            <tr>
                                                <td className='text-center' colSpan={9}>Aucun enregistrement disponible</td>
                                            </tr>
                                            :
                                            this.state.PickedOrder.map((product) => (
                                                <tr className={`${(product.archive) ? 'bg' : ''}`} title={(product.archive) ? 'archive' : ""}>
                                                    <td className="text-center">
                                                        {new Date(product.createdAt).toLocaleDateString() + ' '}
                                                        {new Date(product.createdAt).toLocaleTimeString()}
                                                    </td>
                                                    <td className="text-center">
                                                        {/* {product.en_date_doc} */}
                                                        {new Date(product.en_date_doc).toLocaleDateString() + ' '}
                                                        {new Date(product.en_date_doc).toLocaleTimeString()}
                                                    </td>
                                                    <td className="text-center">{product.en_num_doc}</td>
                                                    {/* <td className="text-center">
                                                    {this.getSVG(product.en_num_doc)}
                                                </td> */}
                                                    <td className="text-center">{product.en_num_ext}</td>
                                                    <td className="text-center">
                                                        {/* {product.en_date2} */}
                                                        {new Date(product.en_date2).toLocaleDateString() + ' '}
                                                        {new Date(product.en_date2).toLocaleTimeString()}
                                                    </td>
                                                    <td className="text-center">
                                                        {(product.etat_lib != 'sav') ? product.etat_lib : ""}

                                                    </td>
                                                    <td className="text-center">{product.op_libel}</td>
                                                    <td className="text-center">{product.res}</td>
                                                    <td className="text-center">{product.marque_logotee}</td>
                                                    {/* <td className="text-center">{product.awb}</td> */}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                <div className='mt-2 pagination justify-content-center'>
                                    {/* <div className='page-item'> */}
                                    <Pagination
                                        page={this.state.page}
                                        pageChange={this.pageChange}
                                        totalPage={this.state.totalPage}
                                    />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default Scan;
